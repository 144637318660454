
        @import "/src/styles/colors.scss";
        
@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600&display=swap");

:root {
  --font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body,
html {
  background-color: #fffaec;
  box-sizing: border-box;
  font-family: var(--font-family-sans-serif);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  word-spacing: 1px;
  -webkit-text-size-adjust: 100%;
  outline: none;
  font-size: 16px;

  :focus {
    outline: none;
  }

  * {
    font-family: var(--font-family-sans-serif);
  }

  color: $cy-black;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.table {
  &.MuiTableContainer-root {
    width: 80%;
  }

  // .MuiTableCell-root {
  //   border-bottom: 0;
  // }
}

.card {
  background: $cy-white;
  border: 1px solid $cy-dark-gray;
  box-shadow: 0px 2px 8px rgba(30, 30, 30, 0.06);
  border-radius: 5px;
}

.card:hover {
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
}

.getting-started-card {
  background: $cy-white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  height: auto;
}

.slack-member {
  background: rgba(var(--sk_highlight_accent, 29, 155, 209), 0.1);
  color: rgba(var(--sk_highlight, 18, 100, 163), 1);
}

.slack-code-block {
  --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  font-family: Monaco, Menlo, Consolas, Courier New, monospace !important;
  font-variant-ligatures: none;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
}

.slack-code {
  padding: 2px 3px 1px;
  border: 1px solid var(--saf-0);
  border-radius: 3px;
  background-color: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  color: #e01e5a;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
